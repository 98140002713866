<template>
  <div class="menus-trash-list-page">
    <v-menus-list :menus="menus" :loading="isMenusLoading" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VMenusList from "../../components/menus/VMenusList";

export default {
  name: "MenusTrashList",

  layout: "default",

  components: { VMenusList },

  metaInfo() {
    return { title: this.$t("Menu.TrashMenus") };
  },

  data() {
    return {
      loading: true,
    };
  },

  computed: mapGetters({
    menus: "menus/deletedMenus",
    isMenusLoading: "menus/isMenusLoading",
  }),

  created() {
    this.$store.dispatch("menus/fetchMenus");
  },

  destroyed() {
    this.$store.dispatch("menus/clearMenus");
  },
};
</script>

<style></style>
